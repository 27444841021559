<template>
  <div class="social-login">
    <!-- <div class="social-heading">
      <p style="color: white; white-space: nowrap;">{{loginTxt}}</p>
    </div> -->
    <div class="social-icons-container">
      <!-- <div @click="inputHandler" class="social-icon" >
        <img v-if="inputEnable" :src="mIcon" alt />
        <img v-else  :src="eIcon" alt />
      </div> -->
      <div @click="toggleIcon" class="social-icon">
        <img :src="currentIcon" :alt="altText" />
      </div>
      <div class="social-icon" v-if="isActiveFacebookLogin" >
        <img @click="facebookLogin" :src="fbIcon" alt />
      </div>
      <div class="social-icon">
        <!-- <img id="Google-SignIn" @click="googleSignIn" :src="gIcon" alt /> -->
        <div id="vLiveGLogin" data-click_listener></div>
      </div>
      
      <!-- <div class="social-icon">
        <img id="Apple-signIn" :src="appleIcon" alt />
      </div>-->
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { _googleId } from "../../../../provider-config.js";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
// import snackbar from "node-snackbar";
import "../../../../../node_modules/node-snackbar/dist/snackbar.css"
// import { showSnackBar } from '../../../../utilities.js';

// /node_modules/node-snackbar/dist/snackbar.css";



export default {
  data() {
    return {
      mIcon:require("@/assets/icons/mobileicon.svg"),
      eIcon:require("@/assets/icons/emailicon.svg"),
      fbIcon: require("@/assets/icons/facebook.svg"),
      appleIcon: require("@/assets/icons/Apple.svg"),
      gIcon: require("@/assets/icons/google.svg"),
      type: null,
      loginTxt: "",
      isActiveFacebookLogin:false,
      isActiveGoogleLogin:false,
      inputEnable: false
    };
  },
  mounted() {
    // this.appleSignin();
    this.loginTxt = this.$t(window.PROVIDER_SETUP_CONFIG.welcome_Txt);
    this.isActiveFacebookLogin = this.appConfig.featureEnabled.isFacebookLoginEnabled;
    this.isActiveGoogleLogin = this.appConfig.featureEnabled.isGoogleLoginEnabled;
   
    let self = this;
      window.google.accounts.id.initialize({
          client_id: _googleId,
           scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: self.handleCredentialResponse.bind(self)
        });

        window.google.accounts.id.renderButton(
            document.getElementById("vLiveGLogin"),
            { type:"icon", shape:"circle",theme:"outline",text:"signup_with", size:"large"}
          );

    // this.googleSignIn()
    eventBus.$on("formType", (value) => {
      this.type = value;
    });

  },

  computed: {
    ...mapGetters(["country", "profileid", "appConfig", "subscriptionList"]),
    currentIcon() {
      return this.inputEnable ? this.mIcon : this.eIcon;
    },
    altText() {
      return this.inputEnable ? "mIcon" : "eIcon";
    }
  },

  created() {
    if ((this.$route.path != '/' && (window.storeAfterLoginPath && !window.storeAfterLoginPath.includes('subscription-plans')))) {
      window.storeAfterLoginPath = this.$route.path;
    }
    eventBus.$on("login-response-social-profile", () => {

      if(this.appConfig.featureEnabled.isMultipleProfileEnabled) {
          let currentLanguage = this.getCurrentLanguageUrlBase();


           this.listProfiles().then((profileResponse) => {
            if (profileResponse.data && profileResponse.data.totalcount > 1) {

              this.$router.push({name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }, query:{"islogin": true}})
              } else {
                
                if ( window.selectedPlanInfomationRouting && !(this.subscriptionList && this.subscriptionList.length > 0)) {
                  this.$router.push({ name: "Home", params: { lang: currentLanguage } });
                      setTimeout (() => {
                        eventBus.$emit("open-home-rzp-modal", window.selectedPlanInfomationRouting);
                        window.selectedPlanInfomationRouting = "";
                      }, 500)
            
                } else {
                  this.$router.push({path: window.storeAfterLoginPath ? window.storeAfterLoginPath : currentLanguage ? '/'+ currentLanguage : '/' });
                }

                window.storeAfterLoginPath = ""

              }
           });
        }

    });

    if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
      eventBus.$on("subscriberDetails-set-success", (data) => {
        console.log("THE SOCIAL LOGIN RESPONSE FROM COMPONENT ---- >subscriberDetails-set-success ", data);
        // if(!data.autologin) {

        //   this.listProfiles().then((profileResponse) => {
        //     if (profileResponse.data.reason) {
        //       // this.formError = this.$t(profileResponse.data.reason);
        //     } else {
  
        //       profileResponse.data.data.forEach((profile, index) => {
        //         if (profile.profileid === this.profileid) {
        //           localStorage.setItem("currentProfileId", profile.profileid);
        //           localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
  
        //           this.setProfileid(profile.profileid);
        //         }
        //       });
        //       if (profileResponse.data.totalcount > 1) {
        //         //this.$router.push("/switchProfile");
				// 				let currentLanguage = this.getCurrentLanguageUrlBase();
      	// 					 this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
        //       } else {
        //         if(profileResponse && profileResponse.data && profileResponse.data.data) { 

        //           let profileData = profileResponse.data.data;
        //           localStorage.setItem("currentProfileId", profileData[0].profileid);
        //           localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileData[0]));
    
        //           this.setProfileid(profileData[0].profileid);
        //         }
        //       }
        //     }
        //   });
          
        // }
      });
    }
    eventBus.$on("social-login-failed", (data) => {

      if(data.errorcode) {
        // snackbar.show({
        //   text: this.$t(data.reason),
        //   textColor: "#ffffff",
        //   pos: "bottom-center",
        //   actionTextColor: "#de2600",
        // });

        // showSnackBar(this.$t(data.reason));
      }
    });

    // if (!window.FB) {

    console.log("inside popup", window.facebookLoaded);
    window.dispatchEvent(window.facebookLoaded);
    // }
  },
  methods: {
    ...mapActions(["listProfiles"]),

    ...mapMutations(["setProfileid"]),
    toggleIcon() {
      this.inputEnable = !this.inputEnable;
      eventBus.$emit("socialLoginToggleIcon", this.inputEnable);
    },
    facebookLogin() {
      if(!this.isActiveFacebookLogin){
        return;
      }
      let self = this;
      /* eslint-disable */
      FB.login(
        function(response) {
          if (response.authResponse) {
            let payload = {
              app: "FB",
              type: self.type ? self.type : "lookup",
              params: {
                devicetype: "PC",
                fbtoken: response.authResponse.accessToken,
                deviceos: self.getDeviceOS(),
                country: self.country ? self.country.CountryCode : "",
              },
            };
            eventBus.$emit("subscriberLogin", payload);
          }
        },
        { scope: "public_profile,email" }
      );
    },
    googleSignIn() {
      if(!this.isActiveGoogleLogin){
        return;
      }
      // document.cookie = 'g_state' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';

      let self = this;
      window.google.accounts.id.initialize({
          client_id: _googleId,
          scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: self.handleCredentialResponse.bind(self)
        });
        window.google.accounts.id.prompt((notification) => {
          console.log("00000", notification);
            if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                document.cookie =  'g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';
                google.accounts.id.prompt()
            }
        });
      // gapi.load("auth2", function() {
      //   let auth2 = gapi.auth2.init({
      //     client_id: _googleId,
      //     cookiepolicy: "single_host_origin",
      //     scope: "profile",
      //   });
   
      //   let element = document.getElementById("Google-SignIn");
      //   auth2.attachClickHandler(element, {}, function(user) {
      //     var profile = user.getBasicProfile();
      //     if (profile.getId()) {
      //       let payload = {
      //         app: "Google",
      //         type: self.type ? self.type : "lookup",
      //         params: {
      //           devicetype: "PC",
      //           deviceos: self.getDeviceOS(),
      //           gtoken: user.getAuthResponse().id_token,
      //           country: self.country ? self.country.CountryCode : "",
      //         },
      //       };
      //       eventBus.$emit("subscriberLogin", payload);
      //     }
      //   });
      // });
    },
    handleCredentialResponse (res) {
      console.log("handleCredentialResponse", JSON.stringify(res))


            let payload = {
              app: "Google",
              type: this.type ? this.type : "lookup",
              params: {
                devicetype: "PC",
                deviceos: this.getDeviceOS(),
                gtoken: res.credential,
                country: this.country ? this.country.CountryCode : "",
              },
            };
            eventBus.$emit("subscriberLogin", payload);
    },
    appleSignin() {
      /* eslint-disable */
      AppleID.auth.init({
        clientId: "com.mobiotics.web.vlive",
        scope: "name email",
        redirectURI: "https://vlivewebapp-5ee2b.firebaseapp.com/",
        state: "qvlmvpyqBmyNx0x7MvBJ3DG7obdbUZ",
        usePopup: true, //or false defaults to false
      });
      let element = document.getElementById("Apple-signIn");
      element.addEventListener("click", function() {
        /* eslint-disable */
        AppleID.auth.signIn().then(
          (response) => {
            if (response.authorization) {
              let payload = {
                app: "Apple",
                params: {
                  devicetype: "PC",
                  deviceos: self.getDeviceOS(),
                  appletoken: response.authorization.id_token,
                },
              };
              eventBus.$emit("subscriberLogin", payload);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
    },
  },

  beforeDestroy() {
    // eventBus.$off("login-response-social-profile");
    eventBus.$off("subscriberDetails-set-success");
  },

  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./socialLogin.scss"
</style>
